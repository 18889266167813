import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Link as ReactScroll } from 'react-scroll';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Arrow from 'src/images/arrow.svg';
import CategoryMenuMobile from './CategoryMenuMobile';
import './IndexPortfolio.scss';
import Button from '../Button';

// let RAME = new Array(25).fill({
//   "slug": "evil",
//   "title": "Evil Project",
//   "subtitle": "HUEHUEHUE EVIL LAUGH",
//   "category": {
//     "name": "illustration"
//   },
//   "thumbnail": {
//     "localFile": {
//       "childImageSharp": {
//         "fluid": {
//           "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABgfVLOYI//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAERIDMv/aAAgBAQABBQICpnMerDHHT10JZv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAICAwEAAAAAAAAAAAAAAAABAhEhMXEi/9oACAEBAAY/Ak0s8PWTVEFeifS2f//EAB0QAAICAQUAAAAAAAAAAAAAAAERADFBIVFhgZH/2gAIAQEAAT8hX7EwMQuShiMBUTHImJW20+R4GVP/2gAMAwEAAgADAAAAEPAv/8QAFxEBAAMAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAMuP/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EIj/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/ECe+4gj4U3jUZaoQXxmHXQiW+u94tylA8ae5AYA5jI2pFZ3P/9k=",
//           "aspectRatio": 1.7857142857142858,
//           "src": "/static/54fa0614bceb8819344b050ad81a9b6a/14b42/image_20.jpg",
//           "srcSet": "/static/54fa0614bceb8819344b050ad81a9b6a/f836f/image_20.jpg 200w,\n/static/54fa0614bceb8819344b050ad81a9b6a/2244e/image_20.jpg 400w,\n/static/54fa0614bceb8819344b050ad81a9b6a/14b42/image_20.jpg 800w,\n/static/54fa0614bceb8819344b050ad81a9b6a/8a0c9/image_20.jpg 875w",
//           "sizes": "(max-width: 800px) 100vw, 800px"
//         }
//       }
//     }
//   }
// })

/**
 * @param {object} props:{
 * projects = all projects from contentful
 * categories = all categories from contentful
 * }
 */
function IndexPortfolio(props) {
  /** Props from Home */
  const { categories, projects } = props;

  /** For Debugging */
  // const { categories } = props
  // let projects = RAME

  /** Current Category */
  const [currentActiveCategory, setCurrentActiveCategory] = useState('all');
  /** Showed projects = always 8 array of objects */
  const [showedProjects, setShowedProjects] = useState(projects.slice(0, 8));
  /** Remaining Project Number, it is recalculated after View More Button is clicked */
  const [remainingProjectNumber, setRemainingProjectNumber] = useState(
    projects.length
  );
  /** To Determine View Mowre Button is visible or not */
  const [canViewMore, setCanViewMore] = useState(
    projects.length > 8 ? true : false
  );
  /** Show Mobile Menu */
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Append "All" category + categories
  const dynamicCategories = [{ name: 'all' }, ...categories];

  // Handle click Category in Desktop view
  const handleClickCategory = event => {
    const category = event.currentTarget.dataset.category;

    // Set active category, eg: '3d
    setCurrentActiveCategory(category);

    // set data based on selected category
    handleFilterSelectedProjects(category);
  };

  // To Filter selected projects based on category (used on mobile and desktop function)
  const handleFilterSelectedProjects = category => {
    // Category all = Reset
    if (category === 'all') {
      setShowedProjects(projects.slice(0, 8));
      setRemainingProjectNumber(projects.length);
      setCanViewMore(projects.length > 8 ? true : false);
      // Filter Based on selected category
    } else {
      // Filter projects based on selected category
      let newSelectedProjects = projects.filter(project =>
        // Find category from categoryReferences (array)
        project?.categoryReferences?.find(
          categoryRef => categoryRef.name === category
        )
      );
      setShowedProjects(newSelectedProjects.slice(0, 8));
      setRemainingProjectNumber(newSelectedProjects.length);
      setCanViewMore(newSelectedProjects.length > 8 ? true : false);
    }
  };

  /** Handle view more = Add 8 or rest projects from selectedProjects[] */
  const handleViewMore = () => {
    const difference = remainingProjectNumber - 8;
    // If difference is positive
    if (difference > 0) {
      setRemainingProjectNumber(difference);
      let tempProjects = [...projects];
      // If difference more than 8
      if (difference > 8) {
        // Add only 8 projects
        let wow = tempProjects.splice(showedProjects.length, 8);
        let concatBOSWOW = showedProjects.concat(wow);
        setShowedProjects(concatBOSWOW);
        setCanViewMore(true);
      } else {
        // Add remaining project numbers
        let wew = tempProjects.splice(
          showedProjects.length,
          showedProjects.length + difference
        );
        let concatBOSWEW = showedProjects.concat(wew);
        setShowedProjects(concatBOSWEW);
        setCanViewMore(false);
      }
      // If difference is negative
    } else {
      setCanViewMore(false);
    }
  };

  const handleMobileCategory = () => setIsMenuOpen(true);

  return (
    <div className='home-portfolio-container' id='home-portfolio-container'>
      {/* Category Button */}
      <div className='home-portfolio-category-container container'>
        {/* Desktop view categories: display all categories */}
        {dynamicCategories?.map((category, index) => {
          return (
            <Fade bottom key={index}>
              <ReactScroll
                activeClass='active'
                to='home-portfolio-container'
                smooth={'easeOutQuart'}
                offset={-80} // Offset to upper side: To make the Categories visible
                duration={500}
              >
                <button
                  key={category.name}
                  className={`single-category-button font-bold hidden sm:block text-base lg:text-3xl ${
                    category.name === currentActiveCategory ? 'active' : ''
                  }`}
                  onClick={handleClickCategory}
                  data-category={category.name}
                >
                  {category?.name?.toUpperCase()}
                </button>
              </ReactScroll>
            </Fade>
          );
        })}
        {/* Mobile view categories: display only active category and a dropdown button */}
        <Fade bottom spy={currentActiveCategory}>
          <button
            className='block sm:hidden flex items-center text-xl text-white cursor-pointer'
            onClick={handleMobileCategory}
          >
            <h2 className='border-red-buttom-ds mr-4 font-bold'>
              {currentActiveCategory.toUpperCase()}
            </h2>
            {/* Accordion */}
            <img src={Arrow} alt='arrow' />
          </button>
        </Fade>
        {/* Red full screen menu for mobile */}
        <CategoryMenuMobile
          categories={dynamicCategories}
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          setCurrentActiveCategory={setCurrentActiveCategory}
          currentActiveCategory={currentActiveCategory}
          handleFilterSelectedProjects={handleFilterSelectedProjects}
        />
      </div>
      {/* Portfolios */}
      <div
        className='home-portfolio-content-wrapper row-helper container mx-auto'
        id='home-portfolio-content-wrapper'
      >
        {showedProjects.length === 0 ? (
          <h2 className='w-full text-center text-white'>Coming soon!</h2>
        ) : (
          showedProjects.map((project, index) => {
            return (
              <Fade bottom key={index}>
                <div
                  className='single-home-portfolio-container col-sm-12 col-md-6 p-2 cursor-pointer relative'
                  style={{ width: '100%' }}
                  key={index}
                >
                  <Link to={`/project/${project?.slug}`}>
                    <Img
                      fluid={
                        project?.thumbnail?.localFile?.childImageSharp?.fluid
                      }
                    />
                    <div className='single-home-portfolio-hoverable-content'>
                      <p className='single-home-portfolio-heading1'>
                        {project?.title || '-'}
                      </p>
                      <p className='single-home-portfolio-heading2'>
                        {project?.subtitle || '-'}
                      </p>
                    </div>
                  </Link>
                </div>
              </Fade>
            );
          })
        )}
      </div>
      {/* View More Button */}
      {canViewMore && (
        <div className='w-full text-center mt-4'>
          <Button onClick={handleViewMore}>VIEW MORE</Button>
        </div>
      )}
    </div>
  );
}

export default IndexPortfolio;

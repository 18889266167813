import { graphql } from 'gatsby';
import React from 'react';
import Hero from 'src/components/Hero';
import Layout from '../layouts/Layout';
import SiteMetadata from 'src/components/SiteMetadata.jsx';
import IndexPortfolio from 'src/components/Home/IndexPortfolio.js';
import MAHHLogo from 'src/images/mahh-logo-black-bg-192.jpg';
import '../styles/index.scss';
// import Cards from "../components/Cards"

const IndexPage = props => {
  return (
    <Layout>
      <SiteMetadata
        title='Home'
        description='MAHH Art - APEPLUS STUDIO: Bunch of creative primates producing feral digital media content based on Indonesia 🇮🇩.'
        image={MAHHLogo}
      />
      <Hero video={props.data.home.video.localFile.publicURL} />
      <IndexPortfolio
        categories={props.data.categories.categories}
        projects={props.data.projects.projects}
      />
      {/* <div className="cards-container">
        <SectionTitle firstWords={'Our'} secondWords={'Services'} />
        {props.data.portfolio && props.data.portfolio.nodes.length > 0 ? (
          <Cards items={props.data.portfolio.nodes} />
        ) : (
            <div className="container">No projects found.</div>
          )}
      </div> */}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    categories: contentfulCategoryList {
      id
      categories {
        name
      }
    }
    projects: contentfulProjectList {
      projects {
        slug
        title
        subtitle
        categoryReferences {
          name
        }
        thumbnail {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    home: contentfulHome {
      video {
        localFile {
          url
          publicURL
        }
      }
    }
  }
`;

// export const query = graphql`
// query HomeQuery {
//   portfolio: allContentfulPortfolio {
//     nodes {
//       ...PortfolioCard
//     }
//   }
// }
// `

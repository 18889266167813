import React from 'react';
import Fade from 'react-reveal/Fade';
import ShowreelPoster from 'src/images/showreel-poster.jpg';
// import MAHHbackground from '../images/hero-background-mahh.png'
// import Showreel from 'src/images/compressed_showreel.mp4';
import MAHHLogo from 'src/images/mahh-logo-512.png';
import './Hero.scss';

const Hero = ({ video }) => (
  <section className='hero-container relative flex-center-center'>
    <Fade bottom>
      <div className='hero-content-container'>
        <div className='hc-upper'>
          <img alt='Logo' src={MAHHLogo} width='32' height='32' />
          <h2 className='ml-4 font-bold text-xl lg:text-5xl'>APEPLUS STUDIO</h2>
        </div>
        <p className='hc-paragraph text-xs lg:text-base mr-4'>
          Bunch of creative primates producing feral digital media content
        </p>
      </div>
    </Fade>
    <div className='gradient flex-center-center'></div>
    {/* Backups */}
    {/* <img alt="Logo" className="hero-image hero-portrait" src="hero-portrait.jpg" /> */}
    {/* <img alt="Logo" className="hero-image hero-landscape" src={MAHHbackground} /> */}
    {/* <img alt="Logo" className="standard-hero" src={MAHHbackground} /> */}

    <video
      className='standard-hero'
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline={true}
      poster={ShowreelPoster}
    >
      <source src={video} type='video/mp4' />
    </video>
  </section>
);

export default Hero;

import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as ReactScroll } from 'react-scroll';
import Overlay from 'src/components/Overlay';

const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: (key) => ({
    opacity: 1,
    transition: {
      // delay: 0.25 + key * 0.1,
      delay: 0.1,
      type: 'tween',
    },
    x: 0,
  }),
};

const CategoryMenuMobile = ({
  categories,
  isOpen,
  setIsOpen,
  setCurrentActiveCategory,
  currentActiveCategory,
  handleFilterSelectedProjects,
}) => {
  const handleSelectedCategory = (key) => {
    setCurrentActiveCategory(key);
    handleFilterSelectedProjects(key);
    setIsOpen(false);
  };
  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='container flex flex-col justify-center'>
        <ul className='text-center'>
          {categories.map((category) => (
            <motion.li
              className='my-3'
              animate={isOpen ? 'open' : 'closed'}
              custom={category.name}
              key={`menu_mobile_link${category.name}`}
              variants={menuItem}
            >
              <ReactScroll
                activeClass='active'
                to='home-portfolio-container'
                smooth={'easeOutQuart'}
                offset={-80} // Offset to upper side: To make the Categories visible
                duration={500}
              >
                <button
                  className={`font-bold text-2xl cursor-pointer text-white ${
                    category.name === currentActiveCategory ? 'underline' : ''
                  }`}
                  onClick={() => handleSelectedCategory(category.name)}
                >
                  {category.name.toUpperCase()}
                </button>
              </ReactScroll>
            </motion.li>
          ))}
        </ul>
      </div>
    </Overlay>
  );
};

CategoryMenuMobile.propTypes = {
  categories: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default CategoryMenuMobile;
